@import "~@styles/variables.scss";

.lyfe-input-wrap {
    display      : flex;
    align-items  : center;
    height       : 56px;
    border-radius: 18px;
    margin       : 0;
    color        : $fontBlack !important;
    font-size    : 16px;
    padding      : 18px 16px;
    transition   : none;

    .ant-input-prefix {
        margin-right: 12px;
    }

    .ant-input-suffix {
        margin-left: 12px;
        height     : 24px;
        width      : 24px;
    }

    .ant-input {
        font-size: 16px;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
        border-color: $colorTeal;
        box-shadow  : none;
    }
}
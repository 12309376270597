@import "~@styles/variables.scss";

img.close-button {
    top : 64px;
    left: 32px;

    @media only screen and (max-width: 767px) {
        top  : 12px;
        right: 24px;
        left : auto;
    }
}

.sign-up-content-wrap {
    display       : flex;
    flex-direction: column;
    height        : 100%;
    padding       : 168px 0 41px;

    .sign-up-title-wrap {
        display: flex;
    }

    .sign-up-form-wrap {
        margin-top: 77px;
        height    : 100%;

        .lyfe-sign-form {
            display        : flex;
            flex-direction : column;
            justify-content: space-between;
            height         : 100%;

            .phone-label {
                line-height  : 24px;
                margin-bottom: 24px;

                img {
                    margin-right: 6px;
                }
            }

            .sign-footer-text {
                text-align: center;
                font-size : 12px;
                margin-top: 24px;
            }

            .sign-button {
                display        : inline-block;
                font-weight    : 600;
                text-decoration: underline;
                cursor         : pointer;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 0 16px;

        .sign-up-form-wrap {
            margin-top: 35px;

            .phone-label {
                margin-bottom: 28px;
            }
        }
    }
}
$fontBlack: #1C0F37;
$fontGrey: #828282;
$colorGrey: #E7E8EB;
$colorTeal: #9DE9F6;
$colorTealDark: #83D6E4;
$colorBlueMain: #2C2E5D;
$colorBlueLight: #41436D;

$colorRed: #EB5757;
$colorSuccess: #27AE60;

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src        : local('Montserrat'), url(../fonts/montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src        : local('Montserrat'), url(../fonts/montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src        : local('Montserrat'), url(../fonts/montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src        : local('Montserrat'), url(../fonts/montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src        : local('Montserrat'), url(../fonts/montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src        : local('Montserrat'), url(../fonts/montserrat/Montserrat-Black.ttf) format('truetype');
}
.order-summary-wrap {
    .summary-content-wrap {
        display       : flex;
        flex-direction: column;

        .content-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            border-top: 1px solid #2C2E5D10;
            font-size: 16px;
        }

        .content-cost-info {}

        .content-delivery-status {}

        .content-summary {
            justify-content: flex-end;

            .summary-value {
                font-weight: 700;
                font-size: 20px;
                margin-left: 10px;
            }
        }
    }
}
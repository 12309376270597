@import "~@styles/variables.scss";

.mtc-layout-wrap {
    display        : flex;
    justify-content: start;
    height         : 100%;
    color          : $fontBlack;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }

    .mtc-col-one {
        height    : 100%;
        width     : 602px;
        flex-grow : 2;
        background: transparent;
        padding   : 40px 46px 52px 52px;


        @media only screen and (max-width: 767px) {
            height    : 100%;
            width     : 100%;
            background: transparent;
            padding   : 0;
        }
    }

    .mtc-col-two {
        width            : 438px;
        height           : 100%;
        min-height       : 736px;
        background       : url(~@assets/img/bg/column_bg.jpg);
        background-repeat: no-repeat;
        background-size  : cover;
        padding          : 40px 20px;

        @media only screen and (max-width: 767px) {
            height    : 100%;
            min-height: 100%;
            width     : 100%;
            background: transparent;
            padding   : 0;
            margin-top: 32px;
        }

    }
}
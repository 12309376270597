.lft-wrapper {
    padding: 52px 84px 63px 100px;

    .flow-head {
        display        : flex;
        justify-content: space-between;
        margin-right   : 16px;
    }

    .kit-info {
        margin-top: 52px;

        .lft-subtitle {
            font-weight  : 400;
            font-size    : 14px;
            line-height  : 17px;
            margin-bottom: 40px;
        }
    }

    .info-graph {
        margin-top: 44px;
        text-align: center;

        img {
            width : 756px;
            height: 526px;
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 4px 24px 235px; // 235 - for fixed button


        .info-graph {
            margin-top: 32px;

            img {
                width : 100%;
                height: auto;
            }
        }
    }
}
.tabs-menu-wrap {
    display    : flex;
    align-items: center;
    width      : 367px;
    height     : 40px;

    .tab-item {
        cursor           : pointer;
        font-size        : 10px;
        font-weight      : 600;
        line-height      : 40px;
        width            : 123px;
        text-align       : center;
        filter           : drop-shadow(0 1px 2px #2c2e5d07) drop-shadow(0 2px 4px #2c2e5d07) drop-shadow(0 4px 8px #2c2e5d07) drop-shadow(0 8px 16px #2c2e5d07) drop-shadow(0 16px 32px #2c2e5d07) drop-shadow(0 32px 64px #2c2e5d07);
        text-transform   : uppercase;

        &:first-child {
            background       : url(~@img/dashboard/navArrDefault.svg);
            background-repeat: no-repeat;
            background-size  : cover;
        }

        &:not(:first-child) {
            background       : url(~@img/dashboard/navArrOpenDefault.svg);
            background-repeat: no-repeat;
            background-size  : cover;
        }

        &:hover,
        &.active {
            color   : #fff;
            filter           : drop-shadow(0 1px 2px #2c2e5d29) drop-shadow(0 2px 4px #2c2e5d29) drop-shadow(0 4px 8px #2c2e5d29) drop-shadow(0 8px 16px #2c2e5d29) drop-shadow(0 16px 32px #2c2e5d29) drop-shadow(0 32px 64px #2c2e5d29);

            &:first-child {
                background       : url(~@img/dashboard/navArrActive.svg);
                background-repeat: no-repeat;
                background-size  : cover;
            }

            &:not(:first-child) {
                background       : url(~@img/dashboard/navArrOpenActive.svg);
                background-repeat: no-repeat;
                background-size  : cover;
            }

        }

        &:not(:first-child) {
            margin-left: -6px;
            width      : 129px;
        }
    }
}
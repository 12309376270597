@import "~@styles/variables.scss";

.dashboard-content-wrap {
    display       : flex;
    flex-direction: column;
    max-height    : 570px;

    .test-info-wrap:not(:last-child) {
        margin-bottom: 16px;
    }
}

.dashboard-detail-wrap {
    .detail-call-title-wrap {
        display    : flex;
        align-items: center;

        img {
            height     : 20px;
            width      : 20px;
            margin-left: 8px;
        }
    }

    .detail-msg {
        color      : $colorRed;
        line-height: 17px;
    }

    .detail-schedule-wrap {
        margin-top: 12px;

        .schedule-item-wrap {
            display: flex;
            align-items: center;
            height: 33px;

            span:first-child {
                width: 34px;
                margin-right: 9px;
                font-weight: 600;
            }
        }
    }
}
.timeline-info-wrapper {
    display: flex;

    .timeline-info-time-col {
        margin-right: 13px;

        .timeline-time-item {
            font-size  : 12px;
            text-align : right;
            color      : #000;
            padding-top: 32px;
        }
    }

    .timeline-info-step-col {
        margin-right: 24px;

        .timeline-step-wrapper {
            width     : 24px;
            text-align: center;
            position  : relative;

            .timeline-circle {
                margin-top   : 28px;
                height       : 24px;
                width        : 24px;
                border-radius: 50%;
                font-size    : 14px;
                border       : 1px solid #000;
            }

            .timeline-line {
                position        : absolute;
                width           : 1px;
                background-color: #2C2E5D40;
                height          : 92px;
                margin          : auto;
                left            : 0;
                right           : 0;
                top             : 24px;
            }
        }
    }

    .timeline-info-descs-col {
        .timeline-descs-wrapper {
            display : flex;
            width   : 480px;
            overflow: hidden;

            img {
                height       : 80px;
                width        : 80px;
                margin-bottom: 4px;
                margin-right : 24px;
            }
            
            .timeline-step-title {
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 8px;
            }

            .timeline-description {
                font-size  : 12px;
                line-height: 18px;
                font-weight: 400;
            }

        }
    }

    .timeline-row {
        height       : 84px;
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
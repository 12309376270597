.lft-block-wrapper {
    display: flex;
    height : 226px;
    width  : 100%;

    .media-content {
        margin-left  : 44px;
        border-radius: 18px;
        overflow     : hidden;
        width        : 405px;

        img {
            width     : 405px;
            height    : 226px;
            object-fit: cover;
        }
    }

    &.second {
        flex-direction: row-reverse;

        .media-content {
            margin-right: 44px;
            margin-left : 0;
        }
    }

    .text-content {
        width : 405px;
        height: 226px;

        .content-title {
            font-weight  : 700;
            font-size    : 18px;
            line-height  : 22px;
            margin-bottom: 16px;
        }

        .content-description {
            font-weight: 400;
            font-size  : 12px;
            line-height: 20px;
            height     : 168px;
        }
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        height        : 100%;

        .media-content {
            margin-top  : 20px;
            margin-right: 0;
            margin-left : 0;
            width       : 100%;

            img {
                width: 100%;
            }
        }

        &.second {
            flex-direction: column-reverse;

            .text-content {
                margin-top: 32px;
            }

            .media-content {
                margin-top  : 0;
                margin-right: 0;
                margin-left : 0;
            }
        }

        .text-content {
            width : 100%;
            height: 100%;

            .content-description {
                height: 100%;
            }
        }
    }
}
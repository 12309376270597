.main-layout-wrapper {
    background: #fff;
    min-width : 1080px;

    .main-layout-content {
        height   : 100vh;
        min-width: 1040px;
        width    : 100%;
        margin   : auto;


        .content-wrapper {
            padding: 5px 0 24px;

            .content {
                position         : relative;
                background       : url(~@assets/img/bg/content_bg.svg);
                background-repeat: no-repeat;
                background-size  : cover;
                width            : 1040px;
                margin           : auto;
                border-radius    : 18px;
                overflow         : hidden;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        background       : url(~@assets/img/bg/mobile_bg.svg);
        background-repeat: no-repeat;
        background-size  : cover;
        min-width        : 375px;

        .main-layout-content {
            min-width: 375px;
            height   : 100%;

            .content-wrapper {
                padding: 4px 24px 48px;

                .content {
                    position     : relative;
                    background   : none;
                    width        : 100%;
                    height       : 100%;
                    margin       : auto;
                    border-radius: 0;
                    overflow     : hidden;
                }
            }
        }
    }
}
@import "~@styles/variables.scss";

.ant-input-number {
    border-radius: 18px;
    height       : 56px;
    width        : 64px;
    margin       : 0;
    color        : $fontBlack !important;
    transition   : none;

    .ant-input-number-handler-wrap {
        display: none;
    }

    .ant-input-number-input-wrap {
        width      : 100%;
        line-height: 56px;

        .ant-input-number-input {
            width     : 100%;
            height    : 100%;
            text-align: center;
            font-size : 20px;
            color     : $fontBlack !important;
        }
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
        border-color: $colorTeal;
        box-shadow  : none;
    }
}
.hiw-wrapper {
    padding: 52px 56px 40px 100px;

    .hiw-description {
        font-size  : 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .hiw-content-wrapper {
        margin-top: 24px;
    }

    .hiw-button-wrapper {
        text-align: right;
    }

    @media only screen and (max-width: 767px) {
        padding: 4px 24px 235px; // for fixed buttons

        .hiw-button-wrapper {
            text-align: center;
        }
    }
}
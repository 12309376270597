@import "~@styles/variables.scss";

.radio-card-wrapper {
    width        : 398px;
    height       : 148px;
    overflow     : hidden;
    border-radius: 18px !important;
    border       : none !important;
    box-shadow   : none;
    padding      : 0;
    z-index      : 0 !important;

    &:hover {
        background   : $colorGrey;
        color        : $fontBlack;
        border-radius: 18px;
        border       : none;
        box-shadow   : none;
    }

    &:active {
        background: $colorTeal;
    }

    &.ant-radio-button-wrapper-checked {
        background: $colorTeal;
    }

    &.ant-radio-button-wrapper-disabled {
        background: $colorGrey;

        .ant-radio-button+span {
            align-items    : center;
            height         : 100%;

            .title-suitable-test {
                margin-bottom: 8px;
            }

            .test-logo {
                margin-right: 14px;
            }
        }
    }

    &:focus-within {
        box-shadow: none !important;
    }

    .ant-radio-button+span {
        padding: 23px 14px 23px 20px;
        display: flex;

        .title-suitable-test {
            line-height  : 20px;
            font-weight  : 700;
            font-size    : 16px;
            margin-bottom: 10px;
        }

        .description {
            font-weight: 400;
            font-size  : 12px;
            line-height: 14.63px;
        }

        .check-status {
            position: absolute;
            top     : 8px;
            right   : 8px;
        }
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

}
@import "~@styles/variables.scss";

.lyfe-card-test-wrap {
    display: flex;
    width  : 100%;
    height : 121px;
    color  : $fontBlack;

    &.small {
        height: 102px;        
    }


    .lyfe-card-logo {

        .test-logo {
            object-fit: fill;
            width     : 128px;
            height    : 121px;

            &.medium {
                width : 110px;
                height: 104px;
            }

            &.small {
                width : 86px;
                height: 81px;
            }
        }
    }

    .lyfe-card-content {
        height: 121px;
        width: 100%;
        margin-left: 24px;

        .card-desc-wrap {
            height: calc(121px - 19.5px - 14px);
        }
    }

    &.small {
        .lyfe-card-content {
            height: 102px;

            .card-desc-wrap {
                height: calc(102px - 19.5px - 10px);
            }

            .card-title {
                margin-bottom: 10px;
            }
        }
    }
}
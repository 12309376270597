@import "~@styles/variables.scss";

.verification-wrap {
    display       : flex;
    flex-direction: column;
    padding       : 168px 0 80px;
    height        : 100%;

    .verification-form-wrap {
        height: 100%;

        .lyfe-verification-form {
            display       : flex;
            flex-direction: column;
            align-items   : center;
            height        : 100%;

            .otp-block-wrap {
                flex: 1;

                .otp-label-wrap {
                    display      : flex;
                    align-items  : flex-start;
                    margin-bottom: 15px;

                    img {
                        margin-right: 6px;
                        margin-top  : 6px;
                    }

                    .otp-label {
                        line-height: 17px;
                        width      : 249px;

                        @media only screen and (max-width: 767px) {
                            width: 100%;
                        }
                    }
                }

                .otp-inputs-wrap {
                    width          : 248px;
                    display        : flex;
                    justify-content: space-between;
                    height         : 61px;
                    margin-left    : 24px;

                    input {
                        width     : 53px;
                        text-align: center;
                        font-size : 28px;
                        border    : none !important;
                    }

                    &.otp-error {
                        input {
                            color: $colorRed !important;
                        }
                    }
                }

                .otp-inputs-wrap.otp-error+.otp-error-text {
                    visibility: visible;
                }

                .otp-error-text {
                    visibility : hidden;
                    color      : $colorRed;
                    font-size  : 12px;
                    line-height: 14.63px;
                    height: 14.63px;
                    margin-left: 24px;
                }

                .otp-resend-block-wrap {
                    margin-top: 35.37px;
                    text-align: center;

                    .otp-resend-timer-wrap {
                        margin-bottom: 20px;
                        font-size    : 16px;
                        line-height  : 19.5px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 0 16px;

        .back-button {
            top : 12px;
            left: 24px;
        }
    }
}
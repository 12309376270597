@import "~@styles/variables.scss";

.test-info-wrap {
    .test-info-created-wrap {
        display      : flex;
        line-height  : 22px;
        font-size    : 12px;
        margin-bottom: 16px;

        .created-date {
            margin-right: 12px;
        }
    }

    &:hover,
    &.active {
        .test-info-created-wrap {
            font-weight: 700;
        }

        .test-card-wrap {
            border-color: $colorTeal;
        }
    }

    .test-card-wrap {
        position       : relative;
        display        : flex;
        justify-content: space-between;
        height         : 140px;
        padding        : 24px 20px 14px;
        background     : #fff;
        border-radius  : 20px;
        cursor         : pointer;
        border         : 3px solid #fff;

        .info-logo-wrap {
            margin-right: 24px;

            .info-logo {
                object-fit: fill;
                width     : 92px;
                height    : 87px;
            }
        }

        .info-content-wrap {
            flex: 1;

            .info-content-title-wrap {
                display: flex;
                align-items: center;

                img {
                    height: 20px;
                    width: 20px;
                    margin-left: 8px;
                }
            }

            .info-status {
                position      : absolute;
                top           : 12px;
                right         : 12px;
                width         : 58px;
                height        : 23px;
                line-height   : 23px;
                text-align    : center;
                text-transform: uppercase;
                border-radius : 20px;
                background    : #27AE6015;
                font-size     : 9px;
                font-weight   : 600;
            }

            .info-content-rowone {
                display   : flex;
                margin-top: 8px;

                .label-text-wrap:first-child {
                    margin-right: 24px;
                }
            }

            .info-content-rowtwo {
                display        : flex;
                justify-content: space-between;
                margin-top     : 7px;

                .label-text-wrap:not(:last-child) {
                    margin-right: 24px;
                }
            }
        }
    }
}
@import "~@styles/variables.scss";

.modal-open {
    // body class
    width   : calc(100% - 17px);
    overflow: hidden;

    @media only screen and (max-width: 767px) {
        overflow: auto;
    }
}

.modal-mask {
    position  : fixed;
    top       : 0;
    right     : 0;
    bottom    : 0;
    left      : 0;
    z-index   : 1000;
    overflow  : hidden;
    background: $colorBlueMain;
    opacity   : 0.47;
    z-index   : 1000;
}

.modal-wrap {
    position                  : fixed;
    top                       : 0;
    right                     : 0;
    bottom                    : 0;
    left                      : 0;
    margin                    : auto;
    overflow                  : auto;
    outline                   : 0;
    -webkit-overflow-scrolling: touch;
    z-index                   : 1000;
    background                : url(~@assets/img/bg/content_bg.svg);
    background-repeat         : no-repeat;
    background-size           : cover;
    padding                   : 0;
    opacity                   : 0;
    border-radius             : 18px;

    &.animation-default {
        animation: showModal 0.5s forwards;

        @keyframes showModal {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

    &.animation-move-left {
        animation: showAuthModal 0.5s forwards;

        @keyframes showAuthModal {
            from {
                transform: translateX(0);
            }

            to {
                transform: translateX(-563px);
            }
        }
    }
}

.mobile-modal-wrap {
    position         : absolute;
    top              : 0;
    right            : 0;
    z-index          : 1000;
    width            : 100%;
    height           : 100%;
    background       : url(~@assets/img/bg/mobile_bg.svg);
    background-repeat: no-repeat;
    background-size  : cover;
    padding          : 0;
    border-radius    : 0;
}
@import "~@styles/variables.scss";

.radio-button-wrapper {
    display        : inline-flex;
    justify-content: center;
    align-items    : center;
    height         : 56px;
    line-height    : 28px;
    background     : #fff;
    overflow       : hidden;
    border-radius  : 18px !important;
    border         : none !important;
    box-shadow     : none;

    &:hover {
        background   : $colorGrey;
        color        : $fontBlack;
        border-radius: 18px;
        border       : none;
        box-shadow   : none;
    }

    &:active {
        background   : $colorTeal;
        color        : $fontBlack;
        font-weight  : 700;
        border-radius: 18px;
        border       : none;
        box-shadow   : none;
    }

    &:focus-within {
        box-shadow: none !important;
    }

    &.ant-radio-button-wrapper-checked {
        background   : #9DE9F6;
        color        : $fontBlack;
        font-weight  : 700;
        border-radius: 18px;
        border       : none;
        box-shadow   : none;

        &:hover {
            color        : $fontBlack;
            border-radius: 18px;
            border       : none;
            box-shadow   : none;
        }
    }

    img {
        margin-right: 8px;
    }

    span {
        font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
        display: flex;
    }
}
@import "~@styles/variables.scss";

label.lyfe-checkbox-wrap {
    font-size  : 12px;
    line-height: 24px;
    color      : $fontBlack;
    align-items: center;


    span.ant-checkbox {
        top: 0;
        box-shadow : 0px 4px 9px 0px #0000000D;

        .ant-checkbox-inner {
            height       : 24px;
            width        : 24px;
            border-radius: 8px;
            border-color : none !important;
            border       : none !important;

            &:hover {
                border-radius: 8px;
                border-color : $colorTealDark !important;
            }

            &:after {
                width    : 8.571428565px;
                height   : 13.71428571px;
                transform: rotate(45deg) scale(1) translate(-50%, -60%);
            }
        }
    }

    .ant-checkbox-checked {
        &:after {
            border-radius: 8px;
            border       : none !important;
        }

        .ant-checkbox-inner {
            background-color: $colorSuccess;
        }
    }
}
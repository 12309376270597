@import "~@styles/variables.scss";

.header-wrapper {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    height         : 100px;
    background     : transparent;
    padding        : 38px 54px 23px 51px;

    .logo {
        line-height: 39px;
        font-size  : 24px;
        flex       : 1;
    }

    .link-wrap {
        display     : flex;
        margin-right: 60px;

        .link-item {
            line-height: 31px;
            color      : $fontBlack;
            font-size  : 14px;
            border-bottom: 3px solid #fff;

            &:not(:last-child) {
                margin-right: 40px;
            }

            &.active {
                font-weight  : 700;
                border-bottom-color: $colorTeal;
            }
        }
    }

    .link-item-btn {
        margin-right: 48px;
        color      : $fontBlack;
        font-size  : 12px;
        text-align: center;
        line-height: 31px;
        width: 123px;
        border-radius: 26px;
        border: 1px solid $colorBlueMain;

        &.active {
            font-weight  : 700;
            border-color: $colorTeal;
        }
    }

    .profile {
        height     : 39px;
        line-height: 39px;
        font-size  : 16px;
    }

    @media only screen and (max-width: 767px) {
        padding: 53px 16px 18px 24px;

        .logo {
            line-height: 29px;
            flex: 0;
        }
    }
}
@import "~@styles/variables.scss";

.ant-slider {
    height: 22px;

    &:hover {
        .ant-slider-track {
            background: $colorTealDark;
        }
    }

    .ant-slider-rail {
        background   : #eaecf2;
        height       : 8px;
        border-radius: 7px;
    }

    .ant-slider-track {
        height    : 8px;
        background: $colorTeal;

        &:hover,
        &:focus {
            background: $colorTealDark;
        }
    }

    .ant-slider-handle {
        margin-top: -8px;
        width     : 24px;
        height    : 24px;
        border    : none;
        background: $colorTeal;
        box-shadow: none;

        &.ant-tooltip-open {
            background: $colorTealDark;
        }
    }

    .ant-slider-step {
        height: 8px;

        .ant-slider-dot {
            top: 0;
        }
    }

    .ant-slider-mark {}
}
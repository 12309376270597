@import "~@styles/variables.scss";

h1.lyfe-title.ant-typography {
    line-height   : 43px;
    font-weight   : 800;
    font-size     : 40px;
    padding-bottom: 0;
    margin-bottom : 24px;
    margin-top    : 0;
    color         : $fontBlack;

    @media only screen and (max-width: 767px) {
        font-size    : 28px;
        line-height  : 33px;
        margin-bottom: 20px;
    }
}

h4.lyfe-title.ant-typography {
    line-height   : 24px;
    font-weight   : 600;
    padding-bottom: 0;
    margin-bottom : 20px;
    margin-top    : 0;
    color         : $fontBlack;


    @media only screen and (max-width: 767px) {
        font-weight  : 700;
        margin-bottom: 16px;
    }
}

h5.lyfe-title.ant-typography {
    line-height   : 19.5px;
    font-weight   : 500;
    padding-bottom: 0;
    margin-bottom : 12px;
    margin-top    : 0;
    color         : $fontBlack;
}
.auth-modal-wrap {
    .auth-wrap {
        display: flex;
        cursor : pointer;

        .auth-header-avatar {
            margin-left  : 7px;
            margin-bottom: 8px;
        }

        @media only screen and (max-width: 767px) {
            .auth-header-avatar {
                margin-left  : 0;
                margin-bottom: 0;
            }
        }
    }

    .modal-wrap {
        right        : -563px;
        left         : auto;
        overflow     : auto;
        width        : 580px;
        padding      : 0 120px;
        border-radius: 0;
        opacity      : 1;
    }

    .mobile-modal-wrap {
        padding: 60px 24px 48px;
    }
}
.timeline-info-mobile-wrapper {
    .timeline-step-wrapper {
        position     : relative;
        display      : flex;
        margin-bottom: 30px;

        .timeline-steps-line {
            position        : absolute;
            width           : 1px;
            background-color: #2C2E5D40;
            height          : calc(100% + 10px);
            left            : 12px;
            top             : 36px;
        }

        .timeline-step-number {
            min-width    : 24px;
            height       : 24px;
            text-align   : center;
            border       : 1px solid #000;
            border-radius: 50%;
            font-size    : 14px;
            margin-top   : 13px;
            margin-right : 12px;
        }

        .timeline-step-content-wrapper {
            display       : flex;
            flex-direction: column;
            overflow      : hidden;
            width         : 100%;

            .timeline-content-header {
                display    : flex;
                align-items: center;

                img {
                    width       : 48px;
                    height      : 48px;
                    margin-right: 12px;
                }

                .timeline-step-title-date {
                    font-size  : 12px;
                    line-height: 14px;
                    color      : #000;
                }

                .timeline-step-title {
                    font-size  : 16px;
                    line-height: 20px;
                    font-weight: 700;
                }
            }

            .timeline-description {
                margin-top   : 12px;
                line-height  : 18px;
                font-weight  : 400;
                font-size    : 12px;
                overflow-wrap: break-word;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
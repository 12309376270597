@import "~@styles/variables.scss";

#choose-form {
    display        : flex;
    justify-content: start;
    height         : 100%;
    color          : $fontBlack;

    h4,
    h5,
    .ant-radio-button-wrapper {
        color: $fontBlack;
    }

    .mtc-col-one {
        .ant-form-item {
            margin: 0;

            .ant-form-item-label {
                padding: 0;
            }
        }

        .purpose-form-item {
            margin-top: 40px;
        }

        .date-time-wrapper {
            display: flex;

            .date-time-divider {
                width: 16px;
            }
        }
    }

    .mtc-col-two {
        .tutorial-msg {
            font-size: 14px;
            color    : $fontBlack;
        }

        .choose-test-wrapper {
            height: 588px;
            margin: 0;

            .choose-test-content {
                height: 588px;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;

        .mtc-col-one {
            .purpose-form-item {
                margin-top: 24px;
            }

            .date-time-wrapper {
                .date-time-divider {
                    width: 12px;
                }
            }
        }

        .mtc-col-two {
            .choose-test-wrapper {
                height: auto;
                margin: 0;

                .choose-test-content {
                    height: auto;
                }
            }
        }

        .mobile-button-wrap {
            display       : flex;
            flex-direction: column;
            padding       : 0 16px;
            width         : 100%;
        }
    }
}
.info-popup-wrap {
    position: relative;
    height  : 100%;
    padding : 52px 60px 51px 32px;

    .close-button {
        top  : 24px;
        right: 24px;
        left : auto;
    }

    .info-popup-rowone {
        display        : inline-flex;
        justify-content: space-between;
        min-width      : 290px;
        margin-left    : auto;
        margin-top     : 22px;

        .label-text-wrap:not(:last-child) {
            margin-right: 24px;
        }
    }

    .info-popup-rowtwo {
        display: flex;
        align-items: center;

        .avatar {
            margin-right: 20px;
            width       : 52px;
            height      : 52px;
        }

        .label-text-wrap:not(:last-child) {
            margin-right: 24px;
        }
    }
}
@import "~@styles/variables.scss";

.label-text-wrap {
    display: inline-block;

    .content-label {
        color         : $fontGrey;
        font-size     : 8px;
        line-height   : 9.75px;
        text-transform: uppercase;
    }

    .content-text {
        color        : $fontBlack;
        font-size    : 14px;
        line-height  : 22px;
        margin-top   : 2px;
        text-overflow: ellipsis;
        overflow     : hidden;
        white-space  : nowrap;
    }
}
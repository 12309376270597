@import "~@styles/variables.scss";

.button-wrapper {
    text-transform: uppercase;
    width         : 220px;
    background    : transparent;
    color         : $fontBlack;
    font-weight   : 600;
    font-size     : 14px;
    line-height   : 17px;
    height        : 56px;
    border-radius : 24px !important;
    border        : 1px solid $colorBlueMain !important;
    overflow      : hidden;
    transition    : none;

    &:hover {
        background : transparent;
        font-weight: 700;
        color      : $fontBlack;
        border     : 1px solid #8688B6 !important;
    }

    &:active,
    &:focus {
        background : transparent;
        font-weight: 600;
        color      : $fontBlack;
    }

    &:disabled {
        color      : $fontBlack;
        font-weight: 600;
        opacity    : 0.3;
        border     : 1px solid $colorBlueMain !important;


        &:focus,
        &:active,
        &:hover {
            background : transparent !important;
            color      : $fontBlack;
            font-weight: 600;
            border     : 1px solid $colorBlueMain !important;
            opacity    : 0.3;
        }
    }

    &.ant-btn-primary {
        background: $colorBlueMain;
        color     : #fff;
        border    : none !important;

        &:hover {
            background : $colorBlueLight !important;
            font-weight: 700;
        }

        &:active,
        &:focus {
            background: $colorBlueMain;
        }

        &:disabled {
            background : $colorBlueMain;
            color      : #fff;
            font-weight: 500;
            opacity    : 0.3;

            &:focus,
            &:active,
            &:hover {
                background: $colorBlueMain !important;
                color     : #fff;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
}
@import "~@styles/variables.scss";

.footer-wrapper {
    display    : flex;
    align-items: center;
    height     : 81px;
    padding    : 20px 150px 32px 150px;
    background : transparent;
    color      : $fontBlack;

    .copyright {
        font-size   : 14px;
        line-height : 29px;
        margin-right: 40px;
    }

    .link-item {
        color       : $fontBlack;
        line-height : 29px;
        font-size   : 12px;
        margin-right: 24px;
    }

    .social-icons {
        height: 29px;
        flex  : 1;
        text-align: right;

        img {
            margin-left: 16px;
        }
    }
}
.privacy-wrap {
    display        : flex;
    flex-direction : column;
    padding        : 88px 0 64px;
    justify-content: space-between;

    .privacy-content {
        flex       : 1;
        font-weight: 400;
        line-height: 22px;
        font-size  : 14px;
    }


    .privacy-button-wrap {
        margin-top: 36px;
    }

    @media only screen and (max-width: 767px) {
        padding: 0;
        
        .privacy-button-wrap {
            margin-bottom: 48px;
        }
    }
}
@import "~@styles/variables.scss";

.lyfe-autocomplete-search-dropdown {
    .ant-select-item {
        color      : $fontBlack;
        font-size  : 14px;
        line-height: 20px;
        min-height : 30px;
        height     : 30px;
        margin-top : 10px;
        padding    : 5px 16px;



        &:hover,
        &:focus,
        &.ant-select-item-option-selected {
            background: $colorTeal;
            font-size : 600;
        }
    }
}
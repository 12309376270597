@import "~@styles/variables.scss";

.time-picker-wrapper {
    width        : 170px;
    height       : 56px;
    padding      : 16px;
    line-height  : 24px;
    border-radius: 18px;
    border       : none;
    overflow     : hidden;

    &:hover {
        box-shadow: none;
        box-shadow: 0 0 0 2px $colorTeal;
    }

    .ant-picker-input {
        flex-direction: row-reverse;

        >input {
            color    : $fontBlack;
            font-size: 16px;
        }

        .ant-picker-suffix {
            margin-left : 0;
            margin-right: 10px;
        }

        .ant-picker-clear {
            display        : flex;
            justify-content: center;
            align-items    : center;
            width          : 24px;
            height         : 24px;
            left           : 0;
            top            : 0;
            transform      : none;
        }
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 16px 10px;
    }
}